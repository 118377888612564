import React from "react";
import { Field, Label, Switch } from "@headlessui/react";
import clsx from "clsx";

export function ToggleButton({
  text,
  enabled,
  onChange,
}: Readonly<{
  text: string;
  enabled: boolean;
  onChange: (b: boolean) => void;
}>) {
  return (
    <Field>
      <div className="flex justify-between gap-2">
        <Label className="group relative cursor-pointer">{text}</Label>
        <Switch
          checked={enabled}
          onChange={onChange}
          className={clsx(
            "relative inline-flex h-5 min-w-9 items-center rounded-full transition-colors",
            enabled ? "bg-neutral-900" : "bg-neutral-200"
          )}
        >
          <span
            className={clsx(
              "inline-block size-3.5 rounded-full bg-white transition-transform",
              enabled ? "translate-x-[18px]" : "translate-x-[3px]"
            )}
          />
        </Switch>
      </div>
    </Field>
  );
}
