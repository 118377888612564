import React, { Fragment } from "react";
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { useAutoProofread } from "@src/hooks/use-auto-proofread";
import { useEditorSettingStore } from "@src/store/editor-setting-store";
import { useUserStore } from "@src/store/user-store";
import { ToggleButton } from "@src/taskpane/components/button/toggle-button";
import { AsahiRuleCategorySettingsButton } from "@src/taskpane/editor-config/asahi-rule-category-settings-button";
import { ColoredToggleButtonWithSettings } from "@src/taskpane/editor-config/colored-toggle-button-with-settings";
import { CustomRuleSettingsButton } from "@src/taskpane/editor-config/custom-rule-settings-button";
import { ProofreadingPrecision } from "@src/taskpane/editor-config/proofreading-precision";
import { TextlintCategorySettingsButton } from "@src/taskpane/editor-config/textlint-category-settings-button";
import { MdClose } from "react-icons/md";

export function EditorConfigModal({
  isOpen,
  setIsOpen,
}: Readonly<{ isOpen: boolean; setIsOpen: (b: boolean) => void }>) {
  const selectedDictId = useUserStore((state) => state.selectedDictId);
  const dictionaries = useUserStore((state) => state.dictionaries);
  const setSelectedDictId = useUserStore((state) => state.setSelectedDictId);

  const isAutoProofreadingEnabled = useEditorSettingStore((state) => state.isAutoProofreadingEnabled);
  const toggleIsAutoProofreadingEnabled = useEditorSettingStore((state) => state.toggleIsAutoProofreadingEnabled);
  const isVerifierModeEnabled = useEditorSettingStore((state) => state.isVerifierModeEnabled);
  const toggleIsVerifierModeEnabled = useEditorSettingStore((state) => state.toggleIsVerifierModeEnabled);

  const { autoProofread } = useAutoProofread();

  const onClose = async () => {
    setIsOpen(false);
    if (isAutoProofreadingEnabled) {
      await autoProofread({ runImmediately: true });
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 font-gothic" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-64 transform rounded-md bg-white text-left align-middle shadow-xl transition-all pb-4">
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="table-cell h-12 w-12 rounded-bl-md bg-neutral-400 p-2.5 text-center align-middle"
                    onClick={onClose}
                  >
                    <MdClose style={{ fontSize: "24px", color: "#fff" }} />
                  </button>
                </div>

                <DialogTitle className="text-center font-bold">校正設定</DialogTitle>
                <div className="flex flex-col items-center space-y-3 pt-3">
                  <div className="flex space-x-2 py-2">
                    <p className="text-sm">AI校正精度</p>
                    <div>
                      <ProofreadingPrecision />
                    </div>
                  </div>

                  <ColoredToggleButtonWithSettings
                    text="ルール辞書"
                    id="asahi-rule-category-settings"
                    areaDecoration="bg-complementary-100"
                  >
                    <AsahiRuleCategorySettingsButton />
                  </ColoredToggleButtonWithSettings>

                  <ColoredToggleButtonWithSettings
                    text="カスタム辞書"
                    id="custom-rule-settings"
                    areaDecoration="bg-yellow-100"
                  >
                    <CustomRuleSettingsButton
                      selectedDictId={selectedDictId}
                      setSelectedDictId={(dictId: string) => setSelectedDictId(dictId)}
                      dictionaries={dictionaries}
                    />
                  </ColoredToggleButtonWithSettings>

                  <ColoredToggleButtonWithSettings
                    text="良文サポート"
                    id="textlint-settings"
                    areaDecoration="bg-green-100"
                  >
                    <TextlintCategorySettingsButton />
                  </ColoredToggleButtonWithSettings>

                  <div className="pt-2 space-y-2">
                    <ToggleButton
                      text="自動校正"
                      enabled={isAutoProofreadingEnabled}
                      onChange={toggleIsAutoProofreadingEnabled}
                    />
                    <ToggleButton
                      text="スマートフィルター"
                      enabled={isVerifierModeEnabled}
                      onChange={toggleIsVerifierModeEnabled}
                    />
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
