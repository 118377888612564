import React, { useMemo } from "react";
import { clsx } from "clsx";

export function FilterButton({
  type,
  text,
  onClick,
  enabled = true,
  errorCount,
}: Readonly<{
  type: "tye" | "asahi" | "custom" | "textlint" | "flamingRisk";
  text: string;
  onClick: () => void;
  enabled: boolean;
  errorCount: number | "-";
}>) {
  const bgColor = useMemo(() => {
    switch (type) {
      case "tye":
        return "bg-primary-100 hover:bg-primary-200";
      case "asahi":
        return "bg-complementary-100 hover:bg-complementary-200";
      case "custom":
        return "bg-yellow-100 hover:bg-yellow-200";
      case "textlint":
        return "bg-green-200 hover:bg-green-300";
      case "flamingRisk":
        return "bg-orange-200 hover:bg-orange-300";
    }
  }, [type]);

  const errorsBgColor = useMemo(() => {
    switch (type) {
      case "tye":
        return "bg-primary-500";
      case "asahi":
        return "bg-complementary-500";
      case "custom":
        return "bg-yellow-500";
      case "textlint":
        return "bg-green-600";
      case "flamingRisk":
        return "bg-orange-600";
    }
  }, [type]);

  const borderColor = useMemo(() => {
    switch (type) {
      case "tye":
        return "border-primary-100";
      case "asahi":
        return "border-complementary-100";
      case "custom":
        return "border-yellow-100";
      case "textlint":
        return "border-green-200";
      case "flamingRisk":
        return "border-orange-200";
    }
  }, [type]);

  const width = useMemo(() => {
    switch (type) {
      case "tye":
        return "w-12";
      case "asahi":
        return "w-16";
      case "custom":
        return "w-[76px]";
      case "textlint":
        return "w-14";
      case "flamingRisk":
        return "w-14";
    }
  }, [type]);

  return (
    <button
      className={clsx(
        width,
        "rounded-full border py-1 text-2xs space-x-1",
        enabled ? [borderColor, bgColor] : "border border-dashed border-neutral-400 bg-white hover:bg-neutral-100"
      )}
      onClick={onClick}
    >
      <span className="font-bold text-neutral-900">{text}</span>
      <span className={clsx("rounded-full px-1 font-bold text-white", errorsBgColor)}>{errorCount}</span>
    </button>
  );
}
