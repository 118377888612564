import { create } from "zustand";
import { persist } from "zustand/middleware";

// 校正精度(0:積極的 1:通常 2:消極的)
const ProofreadingPrecision = {
  ACTIVE: 0,
  NORMAL: 1,
  PASSIVE: 2,
} as const;

export type ProofreadingPrecision = (typeof ProofreadingPrecision)[keyof typeof ProofreadingPrecision];

type EditorSettingState = {
  isTyeErrorVisible: boolean;
  isAsahiRuleErrorVisible: boolean;
  isCustomRuleErrorVisible: boolean;
  isTextlintErrorVisible: boolean;
  isFlamingRiskErrorVisible: boolean;

  // 校正精度(0:積極的 1:通常 2:消極的)
  precision: ProofreadingPrecision;

  // 自動校正のOn/Off
  isAutoProofreadingEnabled: boolean;

  // VerifierのOn/Off
  isVerifierModeEnabled: boolean;

  // 朝日ルールのカテゴリー別指摘の表示
  isAsahiRuleInappropriateExpressionsVisible: boolean;
  isAsahiRuleNonJoyoKanjiVisible: boolean;
  isAsahiRuleNonJoyoReadingVisible: boolean;
  isAsahiRuleCharacterFormVisible: boolean;
  isAsahiRuleMixedScriptVisible: boolean;
  isAsahiRuleKanjiAllowedVisible: boolean;
  isAsahiRuleNumericFormatVisible: boolean;
  isAsahiRuleMisuseVisible: boolean;
  isAsahiRuleIdiomaticExpressionVisible: boolean;
  isAsahiRuleCommonNameVisible: boolean;
  isAsahiRuleKatakanaCautionVisible: boolean;
  isAsahiRuleKanaCautionVisible: boolean;
  isAsahiRuleCharacterTypeCautionVisible: boolean;
  isAsahiRuleHomonymVisible: boolean;
  isAsahiRuleHonorificsCautionVisible: boolean;
  isAsahiRuleKanaReadingVisible: boolean;
  isAsahiRuleInputConversionErrorVisible: boolean;
  isAsahiRuleRedundantExpressionVisible: boolean;
  isAsahiRuleOmittedCharacterVisible: boolean;
  isAsahiRulePlaceNameCautionVisible: boolean;
  isAsahiRuleProperNounCautionVisible: boolean;
  isAsahiRuleTechnicalTermVisible: boolean;

  // textlintのカテゴリー別指摘の表示
  isTextlintNoHankakuKanaVisible: boolean;
  isTextlintNoDoubledJoshiVisible: boolean;
  isTextlintNoDoubleNegativeVisible: boolean;
  isTextlintNoMixDearuDesumasuVisible: boolean;
  isTextlintSentenceLengthVisible: boolean;
  isTextlintNoUnmatchedPairVisible: boolean;
  isTextlintNoDoubledGaVisible: boolean;
  isTextlintNoNfdVisible: boolean;
  isTextlintPreferTariTariVisible: boolean;
  isTextlintNoRedundantExpressionVisible: boolean;
  isTextlintNoSuccessiveWordVisible: boolean;
  isTextlintMaxTenVisible: boolean;
  isTextlintHiraganaKeishikimeishiVisible: boolean;
  isTextlintHiraganaFukushiVisible: boolean;
  isTextlintHiraganaHojodoushiVisible: boolean;
  isTextlintDateWeekdayMismatchVisible: boolean;
  isTextlintNoMixedPeriodVisible: boolean;
  isTextlintNoZenkakuNumbersVisible: boolean;
  isTextlintNoZenkakuAlphabetVisible: boolean;
  isTextlintNoDroppingRaVisible: boolean;
  isTextlintNoDroppingIVisible: boolean;
  isTextlintNoInsertingSaVisible: boolean;
  isTextlintNoSynonymsVisible: boolean;
  isTextlintUnnaturalAlphabetVisible: boolean;
  isTextlintNoHalfWidthSpaceVisible: boolean;
  isTextlintNoDoubledConjunctionVisible: boolean;
  periodAdditionMinLength: number;
};

type EditorSettingActions = {
  toggleIsTyeErrorVisible: () => void;
  toggleIsAsahiRuleErrorVisible: () => void;
  toggleIsCustomRuleErrorVisible: () => void;
  toggleIsTextlintErrorVisible: () => void;
  toggleIsFlamingRiskErrorVisible: () => void;

  setPrecision: (precision: ProofreadingPrecision) => void;

  toggleIsAutoProofreadingEnabled(): void;

  toggleIsVerifierModeEnabled: () => void;

  // 朝日ルール
  toggleIsAsahiRuleInappropriateExpressionsVisible: () => void;
  toggleIsAsahiRuleNonJoyoKanjiVisible: () => void;
  toggleIsAsahiRuleNonJoyoReadingVisible: () => void;
  toggleIsAsahiRuleCharacterFormVisible: () => void;
  toggleIsAsahiRuleMixedScriptVisible: () => void;
  toggleIsAsahiRuleKanjiAllowedVisible: () => void;
  toggleIsAsahiRuleNumericFormatVisible: () => void;
  toggleIsAsahiRuleMisuseVisible: () => void;
  toggleIsAsahiRuleIdiomaticExpressionVisible: () => void;
  toggleIsAsahiRuleCommonNameVisible: () => void;
  toggleIsAsahiRuleKatakanaCautionVisible: () => void;
  toggleIsAsahiRuleKanaCautionVisible: () => void;
  toggleIsAsahiRuleCharacterTypeCautionVisible: () => void;
  toggleIsAsahiRuleHomonymVisible: () => void;
  toggleIsAsahiRuleHonorificsCautionVisible: () => void;
  toggleIsAsahiRuleKanaReadingVisible: () => void;
  toggleIsAsahiRuleInputConversionErrorVisible: () => void;
  toggleIsAsahiRuleRedundantExpressionVisible: () => void;
  toggleIsAsahiRuleOmittedCharacterVisible: () => void;
  toggleIsAsahiRulePlaceNameCautionVisible: () => void;
  toggleIsAsahiRuleProperNounCautionVisible: () => void;
  toggleIsAsahiRuleTechnicalTermVisible: () => void;

  // textlint
  toggleIsTextlintNoHankakuKanaVisible: () => void;
  toggleIsTextlintNoDoubledJoshiVisible: () => void;
  toggleIsTextlintNoDoubleNegativeVisible: () => void;
  toggleIsTextlintNoMixDearuDesumasuVisible: () => void;
  toggleIsTextlintSentenceLengthVisible: () => void;
  toggleIsTextlintNoUnmatchedPairVisible: () => void;
  toggleIsTextlintNoDoubledGaVisible: () => void;
  toggleIsTextlintNoNfdVisible: () => void;
  toggleIsTextlintPreferTariTariVisible: () => void;
  toggleIsTextlintNoRedundantExpressionVisible: () => void;
  toggleIsTextlintNoSuccessiveWordVisible: () => void;
  toggleIsTextlintMaxTenVisible: () => void;
  toggleIsTextlintHiraganaKeishikimeishiVisible: () => void;
  toggleIsTextlintHiraganaFukushiVisible: () => void;
  toggleIsTextlintHiraganaHojodoushiVisible: () => void;
  toggleIsTextlintDateWeekdayMismatchVisible: () => void;
  toggleIsTextlintNoMixedPeriodVisible: () => void;
  toggleIsTextlintNoZenkakuNumbersVisible: () => void;
  toggleIsTextlintNoZenkakuAlphabetVisible: () => void;
  toggleIsTextlintNoDroppingRaVisible: () => void;
  toggleIsTextlintNoDroppingIVisible: () => void;
  toggleIsTextlintNoInsertingSaVisible: () => void;
  toggleIsTextlintNoSynonymsVisible: () => void;
  toggleIsTextlintUnnaturalAlphabetVisible: () => void;
  toggleIsTextlintNoHalfWidthSpaceVisible: () => void;
  toggleIsTextlintNoDoubledConjunctionVisible: () => void;
  setPeriodAdditionMinLength: (periodAdditionMinLength: number) => void;
};

export type EditorSettingStore = EditorSettingState & EditorSettingActions;

const defaultInitState: EditorSettingState = {
  isTyeErrorVisible: true,
  isAsahiRuleErrorVisible: false,
  isCustomRuleErrorVisible: false,
  isTextlintErrorVisible: false,
  isFlamingRiskErrorVisible: true,

  precision: ProofreadingPrecision.NORMAL,

  isAutoProofreadingEnabled: true,

  isVerifierModeEnabled: true,

  isAsahiRuleInappropriateExpressionsVisible: true,
  isAsahiRuleNonJoyoKanjiVisible: true,
  isAsahiRuleNonJoyoReadingVisible: true,
  isAsahiRuleCharacterFormVisible: true,
  isAsahiRuleMixedScriptVisible: true,
  isAsahiRuleKanjiAllowedVisible: true,
  isAsahiRuleNumericFormatVisible: false, // 朝日特有のルールが多いため、false
  isAsahiRuleMisuseVisible: true,
  isAsahiRuleIdiomaticExpressionVisible: true,
  isAsahiRuleCommonNameVisible: true,
  isAsahiRuleKatakanaCautionVisible: false, // 朝日特有のルールが多いため、false
  isAsahiRuleKanaCautionVisible: true,
  isAsahiRuleCharacterTypeCautionVisible: true,
  isAsahiRuleHomonymVisible: false, // 朝日特有のルールが多いため、false
  isAsahiRuleHonorificsCautionVisible: true,
  isAsahiRuleKanaReadingVisible: false, // 朝日特有のルールが多いため、false
  isAsahiRuleInputConversionErrorVisible: true,
  isAsahiRuleRedundantExpressionVisible: true,
  isAsahiRuleOmittedCharacterVisible: true,
  isAsahiRulePlaceNameCautionVisible: true,
  isAsahiRuleProperNounCautionVisible: true,
  isAsahiRuleTechnicalTermVisible: true,

  isTextlintNoHankakuKanaVisible: true,
  isTextlintNoMixDearuDesumasuVisible: true,
  isTextlintNoUnmatchedPairVisible: true,
  isTextlintNoNfdVisible: true,
  isTextlintPreferTariTariVisible: true,
  isTextlintMaxTenVisible: true,
  isTextlintDateWeekdayMismatchVisible: true,
  isTextlintNoZenkakuNumbersVisible: true,
  isTextlintNoZenkakuAlphabetVisible: true,
  isTextlintNoMixedPeriodVisible: true,
  periodAdditionMinLength: 40, // 上記の文末の"。"追加を指摘し始める文字数、デフォルト40
  isTextlintNoDoubledJoshiVisible: true,
  isTextlintNoDoubleNegativeVisible: true,
  isTextlintSentenceLengthVisible: true,
  isTextlintNoDoubledGaVisible: true,
  isTextlintNoRedundantExpressionVisible: true,
  isTextlintNoSuccessiveWordVisible: true,
  isTextlintHiraganaKeishikimeishiVisible: false, // 誤指摘も多いので、false
  isTextlintHiraganaFukushiVisible: true,
  isTextlintHiraganaHojodoushiVisible: true,
  isTextlintNoDroppingRaVisible: true,
  isTextlintNoDroppingIVisible: true,
  isTextlintNoInsertingSaVisible: true,
  isTextlintUnnaturalAlphabetVisible: true,
  isTextlintNoHalfWidthSpaceVisible: false,
  isTextlintNoDoubledConjunctionVisible: true,
  isTextlintNoSynonymsVisible: true,
};

export const useEditorSettingStore = create<EditorSettingStore>()(
  persist(
    (set) => ({
      ...defaultInitState,

      toggleIsTyeErrorVisible: () =>
        set((state) => ({
          isTyeErrorVisible: !state.isTyeErrorVisible,
        })),

      toggleIsAsahiRuleErrorVisible: () =>
        set((state) => ({
          isAsahiRuleErrorVisible: !state.isAsahiRuleErrorVisible,
        })),

      toggleIsCustomRuleErrorVisible: () =>
        set((state) => ({
          isCustomRuleErrorVisible: !state.isCustomRuleErrorVisible,
        })),

      toggleIsTextlintErrorVisible: () =>
        set((state) => ({
          isTextlintErrorVisible: !state.isTextlintErrorVisible,
        })),

      toggleIsFlamingRiskErrorVisible: () =>
        set((state) => ({
          isFlamingRiskErrorVisible: !state.isFlamingRiskErrorVisible,
        })),

      setPrecision: (precision) => set(() => ({ precision })),

      toggleIsAutoProofreadingEnabled() {
        set((state) => ({
          isAutoProofreadingEnabled: !state.isAutoProofreadingEnabled,
        }));
      },

      toggleIsVerifierModeEnabled() {
        set((state) => ({
          isVerifierModeEnabled: !state.isVerifierModeEnabled,
        }));
      },

      toggleIsAsahiRuleInappropriateExpressionsVisible: () =>
        set((state) => ({
          isAsahiRuleInappropriateExpressionsVisible: !state.isAsahiRuleInappropriateExpressionsVisible,
        })),
      toggleIsAsahiRuleNonJoyoKanjiVisible: () =>
        set((state) => ({
          isAsahiRuleNonJoyoKanjiVisible: !state.isAsahiRuleNonJoyoKanjiVisible,
        })),
      toggleIsAsahiRuleNonJoyoReadingVisible: () =>
        set((state) => ({
          isAsahiRuleNonJoyoReadingVisible: !state.isAsahiRuleNonJoyoReadingVisible,
        })),
      toggleIsAsahiRuleCharacterFormVisible: () =>
        set((state) => ({
          isAsahiRuleCharacterFormVisible: !state.isAsahiRuleCharacterFormVisible,
        })),
      toggleIsAsahiRuleMixedScriptVisible: () =>
        set((state) => ({
          isAsahiRuleMixedScriptVisible: !state.isAsahiRuleMixedScriptVisible,
        })),
      toggleIsAsahiRuleKanjiAllowedVisible: () =>
        set((state) => ({
          isAsahiRuleKanjiAllowedVisible: !state.isAsahiRuleKanjiAllowedVisible,
        })),
      toggleIsAsahiRuleNumericFormatVisible: () =>
        set((state) => ({
          isAsahiRuleNumericFormatVisible: !state.isAsahiRuleNumericFormatVisible,
        })),
      toggleIsAsahiRuleMisuseVisible: () =>
        set((state) => ({
          isAsahiRuleMisuseVisible: !state.isAsahiRuleMisuseVisible,
        })),
      toggleIsAsahiRuleIdiomaticExpressionVisible: () =>
        set((state) => ({
          isAsahiRuleIdiomaticExpressionVisible: !state.isAsahiRuleIdiomaticExpressionVisible,
        })),
      toggleIsAsahiRuleCommonNameVisible: () =>
        set((state) => ({
          isAsahiRuleCommonNameVisible: !state.isAsahiRuleCommonNameVisible,
        })),
      toggleIsAsahiRuleKatakanaCautionVisible: () =>
        set((state) => ({
          isAsahiRuleKatakanaCautionVisible: !state.isAsahiRuleKatakanaCautionVisible,
        })),
      toggleIsAsahiRuleKanaCautionVisible: () =>
        set((state) => ({
          isAsahiRuleKanaCautionVisible: !state.isAsahiRuleKanaCautionVisible,
        })),
      toggleIsAsahiRuleCharacterTypeCautionVisible: () =>
        set((state) => ({
          isAsahiRuleCharacterTypeCautionVisible: !state.isAsahiRuleCharacterTypeCautionVisible,
        })),
      toggleIsAsahiRuleHomonymVisible: () =>
        set((state) => ({
          isAsahiRuleHomonymVisible: !state.isAsahiRuleHomonymVisible,
        })),
      toggleIsAsahiRuleHonorificsCautionVisible: () =>
        set((state) => ({
          isAsahiRuleHonorificsCautionVisible: !state.isAsahiRuleHonorificsCautionVisible,
        })),
      toggleIsAsahiRuleKanaReadingVisible: () =>
        set((state) => ({
          isAsahiRuleKanaReadingVisible: !state.isAsahiRuleKanaReadingVisible,
        })),
      toggleIsAsahiRuleInputConversionErrorVisible: () =>
        set((state) => ({
          isAsahiRuleInputConversionErrorVisible: !state.isAsahiRuleInputConversionErrorVisible,
        })),
      toggleIsAsahiRuleRedundantExpressionVisible: () =>
        set((state) => ({
          isAsahiRuleRedundantExpressionVisible: !state.isAsahiRuleRedundantExpressionVisible,
        })),
      toggleIsAsahiRuleOmittedCharacterVisible: () =>
        set((state) => ({
          isAsahiRuleOmittedCharacterVisible: !state.isAsahiRuleOmittedCharacterVisible,
        })),
      toggleIsAsahiRulePlaceNameCautionVisible: () =>
        set((state) => ({
          isAsahiRulePlaceNameCautionVisible: !state.isAsahiRulePlaceNameCautionVisible,
        })),
      toggleIsAsahiRuleProperNounCautionVisible: () =>
        set((state) => ({
          isAsahiRuleProperNounCautionVisible: !state.isAsahiRuleProperNounCautionVisible,
        })),
      toggleIsAsahiRuleTechnicalTermVisible: () =>
        set((state) => ({
          isAsahiRuleTechnicalTermVisible: !state.isAsahiRuleTechnicalTermVisible,
        })),

      toggleIsTextlintNoHankakuKanaVisible: () =>
        set((state) => ({
          isTextlintNoHankakuKanaVisible: !state.isTextlintNoHankakuKanaVisible,
        })),

      toggleIsTextlintNoDoubledJoshiVisible: () =>
        set((state) => ({
          isTextlintNoDoubledJoshiVisible: !state.isTextlintNoDoubledJoshiVisible,
        })),

      toggleIsTextlintNoDoubleNegativeVisible: () =>
        set((state) => ({
          isTextlintNoDoubleNegativeVisible: !state.isTextlintNoDoubleNegativeVisible,
        })),

      toggleIsTextlintNoMixDearuDesumasuVisible: () =>
        set((state) => ({
          isTextlintNoMixDearuDesumasuVisible: !state.isTextlintNoMixDearuDesumasuVisible,
        })),

      toggleIsTextlintSentenceLengthVisible: () =>
        set((state) => ({
          isTextlintSentenceLengthVisible: !state.isTextlintSentenceLengthVisible,
        })),

      toggleIsTextlintNoUnmatchedPairVisible: () =>
        set((state) => ({
          isTextlintNoUnmatchedPairVisible: !state.isTextlintNoUnmatchedPairVisible,
        })),

      toggleIsTextlintNoDoubledGaVisible: () =>
        set((state) => ({
          isTextlintNoDoubledGaVisible: !state.isTextlintNoDoubledGaVisible,
        })),

      toggleIsTextlintNoNfdVisible: () =>
        set((state) => ({
          isTextlintNoNfdVisible: !state.isTextlintNoNfdVisible,
        })),

      toggleIsTextlintPreferTariTariVisible: () =>
        set((state) => ({
          isTextlintPreferTariTariVisible: !state.isTextlintPreferTariTariVisible,
        })),

      toggleIsTextlintNoRedundantExpressionVisible: () =>
        set((state) => ({
          isTextlintNoRedundantExpressionVisible: !state.isTextlintNoRedundantExpressionVisible,
        })),

      toggleIsTextlintNoSuccessiveWordVisible: () =>
        set((state) => ({
          isTextlintNoSuccessiveWordVisible: !state.isTextlintNoSuccessiveWordVisible,
        })),

      toggleIsTextlintMaxTenVisible: () =>
        set((state) => ({
          isTextlintMaxTenVisible: !state.isTextlintMaxTenVisible,
        })),

      toggleIsTextlintHiraganaKeishikimeishiVisible: () =>
        set((state) => ({
          isTextlintHiraganaKeishikimeishiVisible: !state.isTextlintHiraganaKeishikimeishiVisible,
        })),

      toggleIsTextlintHiraganaFukushiVisible: () =>
        set((state) => ({
          isTextlintHiraganaFukushiVisible: !state.isTextlintHiraganaFukushiVisible,
        })),

      toggleIsTextlintHiraganaHojodoushiVisible: () =>
        set((state) => ({
          isTextlintHiraganaHojodoushiVisible: !state.isTextlintHiraganaHojodoushiVisible,
        })),

      toggleIsTextlintDateWeekdayMismatchVisible: () =>
        set((state) => ({
          isTextlintDateWeekdayMismatchVisible: !state.isTextlintDateWeekdayMismatchVisible,
        })),

      toggleIsTextlintNoMixedPeriodVisible: () =>
        set((state) => ({
          isTextlintNoMixedPeriodVisible: !state.isTextlintNoMixedPeriodVisible,
        })),

      setPeriodAdditionMinLength: (periodAdditionMinLength) => set(() => ({ periodAdditionMinLength })),

      toggleIsTextlintNoZenkakuNumbersVisible() {
        set((state) => ({
          isTextlintNoZenkakuNumbersVisible: !state.isTextlintNoZenkakuNumbersVisible,
        }));
      },

      toggleIsTextlintNoZenkakuAlphabetVisible() {
        set((state) => ({
          isTextlintNoZenkakuAlphabetVisible: !state.isTextlintNoZenkakuAlphabetVisible,
        }));
      },

      toggleIsTextlintNoDroppingRaVisible: () =>
        set((state) => ({
          isTextlintNoDroppingRaVisible: !state.isTextlintNoDroppingRaVisible,
        })),

      toggleIsTextlintNoDroppingIVisible() {
        set((state) => ({
          isTextlintNoDroppingIVisible: !state.isTextlintNoDroppingIVisible,
        }));
      },

      toggleIsTextlintNoInsertingSaVisible() {
        set((state) => ({
          isTextlintNoInsertingSaVisible: !state.isTextlintNoInsertingSaVisible,
        }));
      },

      toggleIsTextlintNoSynonymsVisible() {
        set((state) => ({
          isTextlintNoSynonymsVisible: !state.isTextlintNoSynonymsVisible,
        }));
      },

      toggleIsTextlintUnnaturalAlphabetVisible() {
        set((state) => ({
          isTextlintUnnaturalAlphabetVisible: !state.isTextlintUnnaturalAlphabetVisible,
        }));
      },

      toggleIsTextlintNoHalfWidthSpaceVisible() {
        set((state) => ({
          isTextlintNoHalfWidthSpaceVisible: !state.isTextlintNoHalfWidthSpaceVisible,
        }));
      },

      toggleIsTextlintNoDoubledConjunctionVisible() {
        set((state) => ({
          isTextlintNoDoubledConjunctionVisible: !state.isTextlintNoDoubledConjunctionVisible,
        }));
      },
    }),
    {
      name: "editor-settings",
    }
  )
);
