import React from "react";
import { useAlertStore } from "@src/store/alert-store";
import { useProofreadingStore } from "@src/store/proofreading-store";
import { useVisibleErrors } from "@src/store/use-visible-errors";
import { CustomButton } from "@src/taskpane/components/button/custom-button";
import { focusNextErrorPanel } from "@src/taskpane/error-panels/error-panel/focus-next-error-panel";
import { Labels } from "@src/types/editor-response";
import type { TypolessError, TextlintError } from "@src/types/editor-response";
import { ErrorPanelType } from "@src/types/error-panel-type";
import { buildInputTextStates } from "@src/utils/build-Input-text-states";
import { revise } from "./revise";

export function ReviseButton({ targetError, panelType }: { targetError: TypolessError; panelType: ErrorPanelType }) {
  const reviseError = useProofreadingStore((state) => state.reviseError);
  const setSelectedError = useProofreadingStore((state) => state.setSelectedError);
  const inputTextStates = useProofreadingStore((state) => state.inputTextStates);
  const errors = useProofreadingStore((state) => state.errors);

  const showAlert = useAlertStore((state) => state.showAlert);

  const { visibleErrors } = useVisibleErrors();

  const getReviseTextLabel = (error: TypolessError) => {
    if (error.type === "tye") {
      switch (error.label) {
        case Labels.DELETE_LABEL:
          return "トル";
        case Labels.REPLACE_LABEL:
          return `「${error.candidate}」に置換`;
        case Labels.FIRST_ADD_LABEL:
        case Labels.ADD_LABEL:
          return `「${error.candidate}」を挿入`;
        default:
          console.error(`想定外のラベルです: ${error.label}`);
          break;
      }
    }

    if (error.candidate) {
      return `「${error.candidate}」に置換`;
    }
    return "";
  };

  if (targetError.type === "textlint" && targetError.category === "NO_SYNONYMS") {
    const matches = targetError.message.match(/「(.*?)」/g);
    const quotedWords = matches ? matches.map((match) => match.slice(1, -1)) : [];

    return (
      <div className="space-y-2">
        {quotedWords.map((word, i) => (
          <div key={`no_synonyms_${i}`}>
            <CustomButton
              type="dark"
              text={`「${word}」に統一`}
              disabled={panelType !== ErrorPanelType.UNFIXED}
              onClick={async () => {
                for (const noSynonymsError of errors.filter(
                  (error): error is TextlintError =>
                    error.type === "textlint" &&
                    error.category === "NO_SYNONYMS" &&
                    error.message === targetError.message
                )) {
                  const newInputTextStates = await buildInputTextStates(inputTextStates);
                  const { ok } = await revise({
                    targetError: noSynonymsError,
                    inputTextStates: newInputTextStates,
                    word,
                  });
                  if (!ok) {
                    showAlert({
                      alertTitle: "アプリケーションエラー",
                      alertBody: "エラーが発生しました",
                    });
                    return;
                  }
                  reviseError(noSynonymsError);
                }
              }}
            />
          </div>
        ))}
      </div>
    );
  }

  return (
    <CustomButton
      type="dark"
      text={getReviseTextLabel(targetError)}
      disabled={panelType !== ErrorPanelType.UNFIXED}
      onClick={async () => {
        const { ok } = await revise({
          targetError,
          inputTextStates,
        });
        if (!ok) {
          showAlert({
            alertTitle: "アプリケーションエラー",
            alertBody: "エラーが発生しました",
          });
          return;
        }
        reviseError(targetError);
        const newInputTextStates = await buildInputTextStates(inputTextStates);
        await focusNextErrorPanel({
          visibleErrors,
          id: targetError.id,
          setSelectedError,
          inputTextStates: newInputTextStates,
        });
      }}
    />
  );
}
