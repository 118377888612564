import type { InputTextStates } from "@src/store/proofreading-store";
import { Labels } from "@src/types/editor-response";
import type { TypolessError } from "@src/types/editor-response";
import { getOrderNo } from "@src/utils/get-order-no";
import { getPreviousText } from "@src/utils/get-previous-text";

export async function revise({
  targetError,
  inputTextStates,
  word,
}: {
  targetError: TypolessError;
  inputTextStates: InputTextStates[];
  word?: string;
}): Promise<{ ok: boolean }> {
  return await Word.run(async (context) => {
    const results = context.document.body.search(targetError.errorText, {
      matchCase: true,
    });
    results.load("items");
    await context.sync();

    const { ok } = insertText({ results, targetError, inputTextStates, word });
    if (!ok) {
      return { ok: false };
    }

    await context.sync();

    return { ok: true };
  }).catch((error) => {
    console.error(error);
    return { ok: false };
  });
}

function insertText({
  targetError,
  inputTextStates,
  results,
  word,
}: {
  targetError: TypolessError;
  inputTextStates: InputTextStates[];
  results: Word.RangeCollection;
  word?: string;
}) {
  try {
    const previousText = getPreviousText(inputTextStates, targetError.id);
    const orderNo = getOrderNo({ text: targetError.errorText, previousText });

    if (targetError.type === "tye") {
      if (targetError.label === Labels.DELETE_LABEL) {
        results.items[orderNo].insertText("", "Replace");
        return { ok: true };
      }
      if (targetError.label === Labels.FIRST_ADD_LABEL) {
        results.items[orderNo].insertText(targetError.candidate, "Start");
        return { ok: true };
      }
      if (targetError.label === Labels.ADD_LABEL) {
        results.items[orderNo].insertText(targetError.candidate, "End");
        return { ok: true };
      }
      results.items[orderNo].insertText(targetError.candidate, "Replace");
      return { ok: true };
    }

    if (targetError.type === "textlint" && targetError.category === "NO_SYNONYMS") {
      results.items[orderNo].insertText(word, "Replace");
      return { ok: true };
    }

    results.items[orderNo].insertText(targetError.candidate, "Replace");
    return { ok: true };
  } catch (e) {
    console.error(e);
    return { ok: false };
  }
}
