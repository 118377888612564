import React from "react";
import { useEditorSettingStore } from "@src/store/editor-setting-store";
import { TextlintCategory } from "@src/types/textlint-category";
import { MdHelp } from "react-icons/md";
import { useShallow } from "zustand/react/shallow";

export function TextlintCategorySettings() {
  const {
    isTextlintNoHankakuKanaVisible,
    isTextlintNoDoubledJoshiVisible,
    isTextlintNoDoubleNegativeVisible,
    isTextlintNoMixDearuDesumasuVisible,
    isTextlintSentenceLengthVisible,
    isTextlintNoUnmatchedPairVisible,
    isTextlintNoDoubledGaVisible,
    isTextlintNoNfdVisible,
    isTextlintPreferTariTariVisible,
    isTextlintNoRedundantExpressionVisible,
    isTextlintNoSuccessiveWordVisible,
    isTextlintMaxTenVisible,
    isTextlintHiraganaKeishikimeishiVisible,
    isTextlintHiraganaFukushiVisible,
    isTextlintHiraganaHojodoushiVisible,
    isTextlintDateWeekdayMismatchVisible,
    isTextlintNoMixedPeriodVisible,
    periodAdditionMinLength,
    isTextlintNoZenkakuNumbersVisible,
    isTextlintNoZenkakuAlphabetVisible,
    isTextlintNoDroppingRaVisible,
    isTextlintNoDroppingIVisible,
    isTextlintNoInsertingSaVisible,
    isTextlintUnnaturalAlphabetVisible,
    isTextlintNoHalfWidthSpaceVisible,
    isTextlintNoDoubledConjunctionVisible,
    isTextlintNoSynonymsVisible,
    toggleIsTextlintNoHankakuKanaVisible,
    toggleIsTextlintNoDoubledJoshiVisible,
    toggleIsTextlintNoDoubleNegativeVisible,
    toggleIsTextlintNoMixDearuDesumasuVisible,
    toggleIsTextlintSentenceLengthVisible,
    toggleIsTextlintNoUnmatchedPairVisible,
    toggleIsTextlintNoDoubledGaVisible,
    toggleIsTextlintNoNfdVisible,
    toggleIsTextlintPreferTariTariVisible,
    toggleIsTextlintNoRedundantExpressionVisible,
    toggleIsTextlintNoSuccessiveWordVisible,
    toggleIsTextlintMaxTenVisible,
    toggleIsTextlintHiraganaKeishikimeishiVisible,
    toggleIsTextlintHiraganaFukushiVisible,
    toggleIsTextlintHiraganaHojodoushiVisible,
    toggleIsTextlintDateWeekdayMismatchVisible,
    toggleIsTextlintNoMixedPeriodVisible,
    setPeriodAdditionMinLength,
    toggleIsTextlintNoZenkakuNumbersVisible,
    toggleIsTextlintNoZenkakuAlphabetVisible,
    toggleIsTextlintNoDroppingRaVisible,
    toggleIsTextlintNoDroppingIVisible,
    toggleIsTextlintNoInsertingSaVisible,
    toggleIsTextlintNoSynonymsVisible,
    toggleIsTextlintUnnaturalAlphabetVisible,
    toggleIsTextlintNoHalfWidthSpaceVisible,
    toggleIsTextlintNoDoubledConjunctionVisible,
  } = useEditorSettingStore(
    useShallow((state) => ({
      isTextlintNoHankakuKanaVisible: state.isTextlintNoHankakuKanaVisible,
      isTextlintNoDoubledJoshiVisible: state.isTextlintNoDoubledJoshiVisible,
      isTextlintNoDoubleNegativeVisible: state.isTextlintNoDoubleNegativeVisible,
      isTextlintNoMixDearuDesumasuVisible: state.isTextlintNoMixDearuDesumasuVisible,
      isTextlintSentenceLengthVisible: state.isTextlintSentenceLengthVisible,
      isTextlintNoUnmatchedPairVisible: state.isTextlintNoUnmatchedPairVisible,
      isTextlintNoDoubledGaVisible: state.isTextlintNoDoubledGaVisible,
      isTextlintNoNfdVisible: state.isTextlintNoNfdVisible,
      isTextlintPreferTariTariVisible: state.isTextlintPreferTariTariVisible,
      isTextlintNoRedundantExpressionVisible: state.isTextlintNoRedundantExpressionVisible,
      isTextlintNoSuccessiveWordVisible: state.isTextlintNoSuccessiveWordVisible,
      isTextlintMaxTenVisible: state.isTextlintMaxTenVisible,
      isTextlintHiraganaKeishikimeishiVisible: state.isTextlintHiraganaKeishikimeishiVisible,
      isTextlintHiraganaFukushiVisible: state.isTextlintHiraganaFukushiVisible,
      isTextlintHiraganaHojodoushiVisible: state.isTextlintHiraganaHojodoushiVisible,
      isTextlintDateWeekdayMismatchVisible: state.isTextlintDateWeekdayMismatchVisible,
      isTextlintNoMixedPeriodVisible: state.isTextlintNoMixedPeriodVisible,
      periodAdditionMinLength: state.periodAdditionMinLength,
      isTextlintNoZenkakuNumbersVisible: state.isTextlintNoZenkakuNumbersVisible,
      isTextlintNoZenkakuAlphabetVisible: state.isTextlintNoZenkakuAlphabetVisible,
      isTextlintNoDroppingRaVisible: state.isTextlintNoDroppingRaVisible,
      isTextlintNoDroppingIVisible: state.isTextlintNoDroppingIVisible,
      isTextlintNoInsertingSaVisible: state.isTextlintNoInsertingSaVisible,
      isTextlintUnnaturalAlphabetVisible: state.isTextlintUnnaturalAlphabetVisible,
      isTextlintNoHalfWidthSpaceVisible: state.isTextlintNoHalfWidthSpaceVisible,
      isTextlintNoDoubledConjunctionVisible: state.isTextlintNoDoubledConjunctionVisible,
      isTextlintNoSynonymsVisible: state.isTextlintNoSynonymsVisible,

      toggleIsTextlintNoHankakuKanaVisible: state.toggleIsTextlintNoHankakuKanaVisible,
      toggleIsTextlintNoDoubledJoshiVisible: state.toggleIsTextlintNoDoubledJoshiVisible,
      toggleIsTextlintNoDoubleNegativeVisible: state.toggleIsTextlintNoDoubleNegativeVisible,
      toggleIsTextlintNoMixDearuDesumasuVisible: state.toggleIsTextlintNoMixDearuDesumasuVisible,
      toggleIsTextlintSentenceLengthVisible: state.toggleIsTextlintSentenceLengthVisible,
      toggleIsTextlintNoUnmatchedPairVisible: state.toggleIsTextlintNoUnmatchedPairVisible,
      toggleIsTextlintNoDoubledGaVisible: state.toggleIsTextlintNoDoubledGaVisible,
      toggleIsTextlintNoNfdVisible: state.toggleIsTextlintNoNfdVisible,
      toggleIsTextlintPreferTariTariVisible: state.toggleIsTextlintPreferTariTariVisible,
      toggleIsTextlintNoRedundantExpressionVisible: state.toggleIsTextlintNoRedundantExpressionVisible,
      toggleIsTextlintNoSuccessiveWordVisible: state.toggleIsTextlintNoSuccessiveWordVisible,
      toggleIsTextlintMaxTenVisible: state.toggleIsTextlintMaxTenVisible,
      toggleIsTextlintHiraganaKeishikimeishiVisible: state.toggleIsTextlintHiraganaKeishikimeishiVisible,
      toggleIsTextlintHiraganaFukushiVisible: state.toggleIsTextlintHiraganaFukushiVisible,
      toggleIsTextlintHiraganaHojodoushiVisible: state.toggleIsTextlintHiraganaHojodoushiVisible,
      toggleIsTextlintDateWeekdayMismatchVisible: state.toggleIsTextlintDateWeekdayMismatchVisible,
      toggleIsTextlintNoMixedPeriodVisible: state.toggleIsTextlintNoMixedPeriodVisible,
      setPeriodAdditionMinLength: state.setPeriodAdditionMinLength,
      toggleIsTextlintNoZenkakuNumbersVisible: state.toggleIsTextlintNoZenkakuNumbersVisible,
      toggleIsTextlintNoZenkakuAlphabetVisible: state.toggleIsTextlintNoZenkakuAlphabetVisible,
      toggleIsTextlintNoDroppingRaVisible: state.toggleIsTextlintNoDroppingRaVisible,
      toggleIsTextlintNoDroppingIVisible: state.toggleIsTextlintNoDroppingIVisible,
      toggleIsTextlintNoInsertingSaVisible: state.toggleIsTextlintNoInsertingSaVisible,
      toggleIsTextlintNoSynonymsVisible: state.toggleIsTextlintNoSynonymsVisible,
      toggleIsTextlintUnnaturalAlphabetVisible: state.toggleIsTextlintUnnaturalAlphabetVisible,
      toggleIsTextlintNoHalfWidthSpaceVisible: state.toggleIsTextlintNoHalfWidthSpaceVisible,
      toggleIsTextlintNoDoubledConjunctionVisible: state.toggleIsTextlintNoDoubledConjunctionVisible,
    }))
  );

  const textlintCategories: {
    type: TextlintCategory;
    label: string;
    example: string;
    checked: boolean;
    onChange: () => void;
    subLabel?: React.ReactNode;
  }[] = [
    {
      type: TextlintCategory.NO_HANKAKU_KANA,
      label: "半角カナ",
      example: "半角カナを検知します。",
      checked: isTextlintNoHankakuKanaVisible,
      onChange: toggleIsTextlintNoHankakuKanaVisible,
    },
    {
      type: TextlintCategory.NO_MIX_DEARU_DESUMASU,
      label: "「ですます」「である」の混用",
      example: "「です・ます」調と「である」調の混用を検知します。",
      checked: isTextlintNoMixDearuDesumasuVisible,
      onChange: toggleIsTextlintNoMixDearuDesumasuVisible,
    },
    {
      type: TextlintCategory.NO_UNMATCHED_PAIR,
      label: "かっこの不備",
      example: '「 [ ( " などのとじ忘れを検知します。',
      checked: isTextlintNoUnmatchedPairVisible,
      onChange: toggleIsTextlintNoUnmatchedPairVisible,
    },
    {
      type: TextlintCategory.NO_NFD,
      label: "濁点・半濁点の単体利用",
      example: "「゛」や「゜」が1文字として扱われていることを検知します。",
      checked: isTextlintNoNfdVisible,
      onChange: toggleIsTextlintNoNfdVisible,
    },
    {
      type: TextlintCategory.PREFER_TARI_TARI,
      label: "〜たり〜たりの不備",
      example:
        "「〜たり」が繰り返し利用されていないことを検知します。片方が「〜たり」表現なのにもかかわらず、もう片方の動詞が「〜たり」ではない場合をエラーとします。",
      checked: isTextlintPreferTariTariVisible,
      onChange: toggleIsTextlintPreferTariTariVisible,
    },
    {
      type: TextlintCategory.MAX_TEN,
      label: "読点の数が4個以上",
      example: "読点（、）が一文中に4回以上利用されていることを検知します。",
      checked: isTextlintMaxTenVisible,
      onChange: toggleIsTextlintMaxTenVisible,
    },
    {
      type: TextlintCategory.DATE_WEEKDAY_MISMATCH,
      label: "年月日と曜日の不一致",
      example:
        "年月日と曜日が一緒に記載されている場合に、ズレがないかをチェックします。ただしすべてのフォーマット（日付の表記）でチェックが行えるわけではありません。",
      checked: isTextlintDateWeekdayMismatchVisible,
      onChange: toggleIsTextlintDateWeekdayMismatchVisible,
    },
    {
      type: TextlintCategory.NO_ZENKAKU_NUMBERS,
      label: "全角数字",
      example: "全角数字を検知します。\n数字を半角のみに統一したい場合にご利用下さい。",
      checked: isTextlintNoZenkakuNumbersVisible,
      onChange: toggleIsTextlintNoZenkakuNumbersVisible,
    },
    {
      type: TextlintCategory.NO_ZENKAKU_ALPHABET,
      label: "全角アルファベット",
      example:
        "全角アルファベットを検知します。\nアルファベットを半角のみに統一したい場合にご利用下さい。",
      checked: isTextlintNoZenkakuAlphabetVisible,
      onChange: toggleIsTextlintNoZenkakuAlphabetVisible,
    },
    {
      type: TextlintCategory.NO_MIXED_PERIOD,
      label: "文末の句点抜け",
      example:
        "文末に句点(。)が抜けていることを検知します。文末に感嘆符・疑問符（！？）がある場合や、箇条書き（文頭に「・」などがある）の場合は指摘しません。",
      checked: isTextlintNoMixedPeriodVisible,
      onChange: toggleIsTextlintNoMixedPeriodVisible,
      subLabel: (
        <div className="flex items-center gap-1">
          <input
            className="h-5 w-12 rounded border border-slate-500 pl-1 text-sm"
            type="number"
            min="1"
            value={periodAdditionMinLength}
            onChange={(e) => {
              const num = Number(e.target.value);
              if (num > 0) {
                setPeriodAdditionMinLength(num);
              }
            }}
          />
          <p className="text-xs">文字以上の文でチェック</p>
        </div>
      ),
    },
    {
      type: TextlintCategory.NO_DOUBLED_JOSHI,
      label: "助詞の連続",
      example: "同じ助詞が一文中で複数回使われていることを検知します。",
      checked: isTextlintNoDoubledJoshiVisible,
      onChange: toggleIsTextlintNoDoubledJoshiVisible,
    },
    {
      type: TextlintCategory.NO_DOUBLE_NEGATIVE,
      label: "二重否定",
      example: "「〜なくもない」「〜ないことはない」などの二重否定を検知します。",
      checked: isTextlintNoDoubleNegativeVisible,
      onChange: toggleIsTextlintNoDoubleNegativeVisible,
    },
    {
      type: TextlintCategory.SENTENCE_LENGTH,
      label: "100文字より長い文章",
      example: "一般的に長すぎるとされる、100文字より長い文章を検知します。",
      checked: isTextlintSentenceLengthVisible,
      onChange: toggleIsTextlintSentenceLengthVisible,
    },
    {
      type: TextlintCategory.NO_DOUBLED_GA,
      label: "「が」の重複",
      example: "助詞「が」が一文中で複数回使われていることを検知します。",
      checked: isTextlintNoDoubledGaVisible,
      onChange: toggleIsTextlintNoDoubledGaVisible,
    },
    {
      type: TextlintCategory.NO_REDUNDANT_EXPRESSION,
      label: "冗長な表現",
      example:
        "一部を省略しても意味が通じる表現を検知します。具体的には以下のパターンを検知します。\n①：することが[できる・可能だ] -> できる・可能だ\n②：であると[言える・考えている] -> と言える・考えている\n③：[サ変名詞]を[行う・実行する] -> [サ変名詞]する",
      checked: isTextlintNoRedundantExpressionVisible,
      onChange: toggleIsTextlintNoRedundantExpressionVisible,
    },
    {
      type: TextlintCategory.NO_SUCCESSIVE_WORD,
      label: "同一単語の連続",
      example: "同じ単語の連続利用を検知します。",
      checked: isTextlintNoSuccessiveWordVisible,
      onChange: toggleIsTextlintNoSuccessiveWordVisible,
    },
    {
      type: TextlintCategory.HIRAGANA_KEISHIKIMEISHI,
      label: "形式名詞の漢字表記",
      example:
        "漢字よりもひらがなで表記したほうが読みやすい形式名詞を検知します。具体的には以下を検知します。\n〜する上(で)、〜する事(が)、〜する度(に)、〜する方(が)、〜する時(に)、〜する毎(に)、〜する為(に)、〜する訳(が)、〜する所(だ)、〜する通り(に)、〜する物(だ)",
      checked: isTextlintHiraganaKeishikimeishiVisible,
      onChange: toggleIsTextlintHiraganaKeishikimeishiVisible,
    },
    {
      type: TextlintCategory.HIRAGANA_FUKUSHI,
      label: "副詞の漢字表記",
      example: "漢字よりもひらがなで表記したほうが読みやすい副詞を検知します。（例）敢えて、一旦、未だに、更に",
      checked: isTextlintHiraganaFukushiVisible,
      onChange: toggleIsTextlintHiraganaFukushiVisible,
    },
    {
      type: TextlintCategory.HIRAGANA_HOJODOUSHI,
      label: "補助動詞の漢字表記",
      example:
        "漢字よりもひらがなで表記したほうが読みやすい補助動詞を検知します。具体的には以下を検知します。\n〜して頂く、〜して下さい、〜して行く、〜して来る、〜致します、〜し出す",
      checked: isTextlintHiraganaHojodoushiVisible,
      onChange: toggleIsTextlintHiraganaHojodoushiVisible,
    },
    {
      type: TextlintCategory.NO_DROPPING_RA,
      label: "「ら」抜き言葉",
      example: "ら抜き言葉を検知します。",
      checked: isTextlintNoDroppingRaVisible,
      onChange: toggleIsTextlintNoDroppingRaVisible,
    },
    {
      type: TextlintCategory.NO_DROPPING_I,
      label: "「い」抜き言葉",
      example: "「〜てます」「〜てない」などのい抜き言葉を検知します。",
      checked: isTextlintNoDroppingIVisible,
      onChange: toggleIsTextlintNoDroppingIVisible,
    },
    {
      type: TextlintCategory.NO_INSERTING_SA,
      label: "「さ」入れ言葉",
      example: "「〜したさそう」という形式のさ入れ言葉を検知します。",
      checked: isTextlintNoInsertingSaVisible,
      onChange: toggleIsTextlintNoInsertingSaVisible,
    },
    {
      type: TextlintCategory.UNNATURAL_ALPHABET,
      label: "不自然なアルファベット",
      example: "日本語文章中に混入した、不自然なアルファベットを検知します。",
      checked: isTextlintUnnaturalAlphabetVisible,
      onChange: toggleIsTextlintUnnaturalAlphabetVisible,
    },
    {
      type: TextlintCategory.NO_HALF_WIDTH_SPACE,
      label: "不自然な半角スペース",
      example:
        "文字の間に入った不自然なスペースを検知します。\n× これは_だめ\n× これも_NG\n○ This_is_OK!",
      checked: isTextlintNoHalfWidthSpaceVisible,
      onChange: toggleIsTextlintNoHalfWidthSpaceVisible,
    },
    {
      type: TextlintCategory.NO_DOUBLED_CONJUNCTION,
      label: "同一接続詞の連続利用",
      example: "「しかし、〜。しかし、〜」のように、連続した文で同じ接続詞が使われていることを検知します。",
      checked: isTextlintNoDoubledConjunctionVisible,
      onChange: toggleIsTextlintNoDoubledConjunctionVisible,
    },
    {
      type: TextlintCategory.NO_SYNONYMS,
      label: "名詞表記揺れチェック",
      example: "同じ意味の名詞が複数の表記で表現されていることを検知します。\n（例）ユーザーとユーザ、PCとパソコンなど",
      checked: isTextlintNoSynonymsVisible,
      onChange: toggleIsTextlintNoSynonymsVisible,
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      <div className="flex w-full items-center justify-center gap-2">
        <div className="font-bold text-green-600 text-sm">【良文サポート】チェック項目の設定</div>
        <MdHelp
          className="w-6 h-6 cursor-pointer"
          onClick={() => {
            window.open(`${process.env.TYPOLESS_WEB_URL}/guide/premium/textlint-category`, "_blank");
          }}
        />
      </div>
      <div className="grid grid-cols-1 gap-5 px-3 py-4 md:grid-cols-[auto_auto]">
        {textlintCategories.map(({ type, label, checked, onChange, subLabel }) => (
          <div key={type} className="flex items-center gap-3 accent-green-600">
            <input id={type} type="checkbox" checked={checked} onChange={onChange} />
            <label className="group relative" htmlFor={type}>
              <p className="text-sm font-bold hover:opacity-70">{label}</p>
            </label>
            {subLabel}
          </div>
        ))}
      </div>
    </div>
  );
}
