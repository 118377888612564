import type { InputTextStates } from "@src/store/proofreading-store";
import { getAsahiRuleSubCategoryJa } from "@src/types/asahi-rule-category";
import { Labels } from "@src/types/editor-response";
import type { TypolessError } from "@src/types/editor-response";
import { getTextlintCategoryJa } from "@src/types/textlint-category";
import { getOrderNo } from "@src/utils/get-order-no";
import { getPreviousText } from "@src/utils/get-previous-text";

export async function insertComment({
  targetError,
  inputTextStates,
}: {
  targetError: TypolessError;
  inputTextStates: InputTextStates[];
}): Promise<{ ok: true; commentId: string } | { ok: false }> {
  return await Word.run(async (context) => {
    const results = context.document.body.search(targetError.errorText, {
      matchCase: true,
    });
    results.load("items");
    await context.sync();

    const orderNo = getOrderNo({
      text: targetError.errorText,
      previousText: getPreviousText(inputTextStates, targetError.id),
    });

    const comment = results.items[orderNo].insertComment(getComment(targetError));
    comment.load();
    await context.sync();

    const commentId = comment.id;
    return { ok: true, commentId };
  }).catch((error) => {
    console.error(error);
    return { ok: false };
  });
}

function getComment(error: TypolessError) {
  let comment = getLabelName(error);

  if (error.type === "tye") {
    switch (error.label) {
      case Labels.DELETE_LABEL:
        return (comment += "\n" + "トル");
      case Labels.REPLACE_LABEL:
        return (comment += "\n" + "「" + error.candidate + "」に置換");
      case Labels.FIRST_ADD_LABEL:
      case Labels.ADD_LABEL:
        return (comment += "\n" + "「" + error.candidate + "」を挿入");
    }
  }

  if (error.message) {
    comment += "\n" + error.message;
  }

  // textlintのカテゴリーがNO_SYNONYMSの場合はmessageに置換候補を記述しているため条件に含めない
  if (
    error.candidate &&
    error.candidate !== "null" &&
    !(error.type === "textlint" && error.category === "NO_SYNONYMS")
  ) {
    comment += "\n" + "「" + error.candidate + "」に置換";
  }
  return comment;
}

function getLabelName(error: TypolessError) {
  if (error.type === "tye") {
    switch (error.label) {
      case Labels.DELETE_LABEL:
        return "削除候補";
      case Labels.REPLACE_LABEL:
        return "置換候補";
      case Labels.FIRST_ADD_LABEL:
      case Labels.ADD_LABEL:
        return "追加候補";
    }
  }

  if (error.type === "asahiRule") {
    return error.categories
      .map((category) => getAsahiRuleSubCategoryJa(category))
      .filter((value) => value)
      .join("・");
  }

  if (error.type === "customRule") {
    if (error.isFuzzyMatch) {
      return "カスタム辞書【もしかして】";
    }
    return "カスタム辞書【完全一致】";
  }

  if (error.type === "textlint") {
    return getTextlintCategoryJa(error.category);
  }

  if (error.type === "flamingRisk") {
    return "炎上リスク";
  }
  return "";
}
