import * as React from "react";
import { useEffect, useState } from "react";
import { useAlertStore } from "@src/store/alert-store";
import { useEditorSettingStore } from "@src/store/editor-setting-store";
import { useProofreadingStore } from "@src/store/proofreading-store";
import { useVisibleErrors } from "@src/store/use-visible-errors";
import { HelpButton } from "@src/taskpane/components/button/help-button";
import { LogoutButton } from "@src/taskpane/components/button/logout-button";
import { FilterButtons } from "@src/taskpane/components/filter-buttons";
import { AlertModal } from "@src/taskpane/components/modal/alert-modal";
import { AnnouncementModal } from "@src/taskpane/components/modal/announcement-modal";
import { EditorConfigButton } from "@src/taskpane/editor-config/editor-config-button";
import { FlamingRiskCheckButton } from "@src/taskpane/proofreading/flaming-risk-check-button";
import { ProofreadingButton } from "@src/taskpane/proofreading/proofreading-button";
import type { IgnoredTypolessError } from "@src/types/editor-response";
import { ErrorPanels } from "../error-panels/error-panels";
import { ProofreadingProgress } from "../proofreading/proofreading-progress";

const TextInsertion = () => {
  const [isLoading, setIsLoading] = useState(true);
  const isShowingAlert = useAlertStore((state) => state.isShowingAlert);
  const showAlert = useAlertStore((state) => state.showAlert);
  const ignoreError = useProofreadingStore((state) => state.ignoreError);
  const setMaxTextLength = useProofreadingStore((state) => state.setMaxTextLength);

  const isTextlintNoSynonymsVisible = useEditorSettingStore((state) => state.isTextlintNoSynonymsVisible);
  const toggleIsTextlintNoSynonymsVisible = useEditorSettingStore((state) => state.toggleIsTextlintNoSynonymsVisible);
  const isAutoProofreadingEnabled = useEditorSettingStore((state) => state.isAutoProofreadingEnabled);

  useEffect(() => {
    (async () => {
      try {
        const encodedCredentials = btoa(
          `${process.env.TYPOLESS_BASIC_AUTHORIZATION_USER_NAME}:${process.env.TYPOLESS_BASIC_AUTHORIZATION_PASSWORD}`
        );
        const idTokenResponse = await fetch(`${process.env.TYPOLESS_WORD_ADD_IN_API_URL}/auth/refresh-token`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: process.env.NODE_ENV === "development" && `Basic ${encodedCredentials}`,
          },
          credentials: "include",
        });
        if (!idTokenResponse.ok) {
          throw new Error();
        }
        const res = await fetch(`${process.env.TYPOLESS_WORD_ADD_IN_API_URL}/user/get-user-setting`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: process.env.NODE_ENV === "development" && `Basic ${encodedCredentials}`,
          },
          credentials: "include",
        });
        if (!res.ok) {
          throw new Error();
        }
        const data = await res.json();
        data.ignoredAsahiRules.forEach((rule: IgnoredTypolessError) => {
          ignoreError(rule, true);
        });

        const maxTextLength = data.maxTextLength;
        setMaxTextLength(maxTextLength);
      } catch (e) {
        console.error(e);
        showAlert({
          alertTitle: "アプリケーションエラー",
          alertBody: "エラーが発生しました",
          isLogout: true,
        });
        return;
      } finally {
        setIsLoading(false);
      }
    })();
  }, [ignoreError, isTextlintNoSynonymsVisible, setMaxTextLength, showAlert, toggleIsTextlintNoSynonymsVisible]);

  const { visibleErrors } = useVisibleErrors();

  // 永続無視リストの取得が完了するまで自動校正を実行しないようにする
  // 自動校正はProofreadingProgressで行っている
  if (isLoading) {
    return (
      <div className="flex justify-center min-h-screen">
        <div className="flex flex-col justify-center items-center space-y-1.5" aria-label="読み込み中">
          <div className="size-5 animate-spin rounded-full border-2 border-b-neutral-500 border-t-transparent" />
          <p className="font-bold text-xs">読み込み中</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="min-w-72 space-y-2">
        <div className="flex justify-between">
          <div className="flex p-2 space-x-4">
            {!isAutoProofreadingEnabled && <ProofreadingButton />}
            <EditorConfigButton />
            <HelpButton />
            <LogoutButton />
            {isAutoProofreadingEnabled && (
              <>
                <FlamingRiskCheckButton />
                <ProofreadingProgress />
              </>
            )}
          </div>
        </div>
        <FilterButtons />
        <ErrorPanels errors={visibleErrors} />
      </div>
      <AnnouncementModal />
      <AlertModal isOpen={isShowingAlert} />
    </>
  );
};

export default TextInsertion;
