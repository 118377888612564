import React from "react";
import { useAlertStore } from "@src/store/alert-store";
import { useProofreadingStore } from "@src/store/proofreading-store";
import { useVisibleErrors } from "@src/store/use-visible-errors";
import { DescriptionTooltip } from "@src/taskpane/components/tooltip/description-tooltip";
import { focusNextErrorPanel } from "@src/taskpane/error-panels/error-panel/focus-next-error-panel";
import { insertComment } from "@src/taskpane/error-panels/insert-comment-button/insert-comment";
import type { TypolessError } from "@src/types/editor-response";
import { buildInputTextStates } from "@src/utils/build-Input-text-states";
import { LiaCommentAlt } from "react-icons/lia";

export function InsertCommentButton({
  targetError,
  isProcessing,
  setIsProcessing,
}: {
  targetError: TypolessError;
  isProcessing: boolean;
  setIsProcessing: (b: boolean) => void;
}) {
  const inputTextStates = useProofreadingStore((state) => state.inputTextStates);
  const commentError = useProofreadingStore((state) => state.commentError);
  const setSelectedError = useProofreadingStore((state) => state.setSelectedError);
  const errors = useProofreadingStore((state) => state.errors);

  const showAlert = useAlertStore((state) => state.showAlert);

  const { visibleErrors } = useVisibleErrors();

  const onClick = async (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setIsProcessing(true);

    const commentErrors = getCommentErrors({ targetError, errors });
    const commentIds = [];
    const newInputTextStates = await buildInputTextStates(inputTextStates);
    for (const commentError of commentErrors) {
      const res = await insertComment({ targetError: commentError, inputTextStates: newInputTextStates });
      if (!res.ok) {
        showAlert({
          alertTitle: "アプリケーションエラー",
          alertBody: "エラーが発生しました",
        });
        setIsProcessing(false);
        return;
      }
      const commentId = res.commentId;
      commentIds.push(commentId);
    }

    setIsProcessing(false);

    setTimeout(async () => {
      commentErrors.forEach((error, index) => {
        commentError(error, commentIds[index]);
      });

      if (commentErrors.length === 1) {
        await focusNextErrorPanel({
          visibleErrors,
          id: targetError.id,
          setSelectedError,
          inputTextStates: newInputTextStates,
        });
      }
    });
  };

  return (
    <DescriptionTooltip text="コメントを追加する">
      <button className="flex text-[#BDBDBD]" onClick={onClick} disabled={isProcessing}>
        <LiaCommentAlt size={24} />
      </button>
    </DescriptionTooltip>
  );
}

function getCommentErrors({
  targetError,
  errors,
}: {
  targetError: TypolessError;
  errors: TypolessError[];
}): TypolessError[] {
  if (targetError.type === "textlint" && targetError.category === "NO_SYNONYMS") {
    const filteredErrors = errors.filter((error) => {
      return error.type === "textlint" && error.category === "NO_SYNONYMS" && error.message === targetError.message;
    });

    return filteredErrors;
  }
  return [targetError];
}
